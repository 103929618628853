import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap'
import appStore from '../../images/badge-download-on-the-app-store.svg'
import macAppStore from '../../images/badge-download-on-the-mac-app-store.svg'

import { List, Card, CardImg, Col, Container, Row } from 'reactstrap'
import id700wave from '../../images/ID700-ios-large-1.png'
import id700env from '../../images/ID700-ios-large-0.png'
import id700tuning from '../../images/ID700-ios-large-2.png'
import id700manual from '../../images/ID700-ios-large-3.png'

const Review = ({ href, author, children } = {}) => (
  <div className="d-flex flex-column justify-content-between">
    <div className="p-1">{children}</div>
    <div className="p-1">
      <a target="_blank" rel="noreferrer" href={href}>
        {'-'}&nbsp;{author}
      </a>
    </div>
  </div>
)

const DropboxLink = ({ href, children } = {}) => {
  const parsedUrl = new URL(href)
  const query = new URLSearchParams(parsedUrl.search)
  // make sure to set ?dl=1 or else people get prompted to login to dropbox
  query.set('dl', '1')
  parsedUrl.search = `?${query.toString()}`
  return <a href={parsedUrl.href}>{children}</a>
}

const items = [
  {
    src: id700env,
  },
  {
    src: id700wave,
  },
  {
    src: id700tuning,
  },
  {
    src: id700manual,
  },
]

const IndexPage = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <CardImg src={item.src} alt={item.altText} />
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        />
      </CarouselItem>
    )
  })

  return (
    <Layout>
      <Container fluid>
        <Helmet title="ID700" />
        <Row noGutters>
          <Col xs={12} sm={{ size: 10, offset: 1 }}>
            <div className="d-sm-none">
              <h1 className="text-center">ID700</h1>
            </div>
            <div className="mt-1 mb-1 d-flex flex-row justify-content-between align-items-center">
              <h1 className="mb-1 d-none d-sm-flex">ID700</h1>
              <a href="/id700#history">History</a>
              <a href="/id700/#features">Features</a>
              <a href="/id700#reviews">Reviews</a>
              <a href="/id700#downloads">Downloads</a>
              <a href="/id700/support">Support</a>
            </div>
          </Col>
          <Col xs={12} sm={{ size: 10, offset: 1 }}>
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
              <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {slides}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
              />
            </Carousel>
          </Col>
          <Col xs={12} sm={{ size: 10, offset: 1 }}>
            <div className="pl-md-3 text-xs-justify">
              <hr />
              <div className="d-flex flex-row justify-content-between">
                <div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://apps.apple.com/app/id700-for-desktop/id1597187741"
                  >
                    <Card className="border-0">
                      <CardImg src={macAppStore} />
                    </Card>
                  </a>
                </div>
                <div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://apps.apple.com/app/id1482698043"
                  >
                    <Card className="border-0">
                      <CardImg src={appStore} />
                    </Card>
                  </a>
                </div>
              </div>
              <hr />
              <h3 className="font-weight-light">
                ID700 is a hybrid FM / waveshaping software synthesizer based on
                the voice architecture of the Buchla 700.
              </h3>
              <h3 id="history">History</h3>
              <p>
                In 1987 Don Buchla released the Buchla 700 synthesizer. It was
                the next logical step from Don's previous digital synths (the
                Buchla 400, Touché, Buchla 500) but was also influenced by the
                synth trends of the 1980's, specifically FM synthesis and the
                all-in-one workstation concept. As usual, Don was ahead of the
                curve and the 700 never took off. Very few were sold and even
                fewer functioning units exist today. I always loved the sound of
                Don's digital oscillators, and combined with the scarcity of the
                instrument it seemed like a fun project to recreate it in
                software and reintroduce its concepts to the world.
              </p>
              <hr />
              <h3 id="features">Features</h3>
              <List>
                <li>
                  standalone, AUv3 (Logic Pro, GarageBand, AUM, etc) and IAA
                  support on iOS
                </li>
                <li>
                  standalone, AUv3 (GarageBand, Logic, MainStage, REAPER64) and
                  VST3
                  <a href="#section1">
                    <sup>[1]</sup>
                  </a>{' '}
                  (Ableton Live, Bitwig Studio, FL Studio, Cubase) support on
                  macOS
                </li>
                <li>Logic Pro sessions work across iOS and macOS</li>
                <li>
                  twelve voices of four operator FM with twelve unique
                  algorithms
                </li>
                <li>fourteen complex envelopes per voice</li>
                <li>over two hundred professionally designed presets</li>
                <li>two waveshapers per voice</li>
                <li>
                  over forty factory wave shapes plus an editor for user created
                  shapes
                </li>
                <li>
                  Scala scale file support with over four thousand included
                  tunings from the Scala scale archive
                </li>
                <li>
                  MPE (MIDI Polyphonic Expression) capability for MPE
                  controllers like the Roli Seabord, Linnstrument, and Sensel
                  Morph
                </li>
              </List>
              <p className="font-weight-light">
                <a id="section1">
                  <sup>[1]</sup>
                </a>{' '}
                VST3 support requires installing a separate VST3 wrapper
                available for free in the <a href="#downloads">Downloads</a>{' '}
                section
              </p>

              <hr />
              <h3 id="reviews">Reviews</h3>
              <div>
                <Review
                  href="https://cdm.link/2021/04/id700-for-ipad-reboots-the-1987-buchla-700-fm-waveshaping-and-polyphony-hands-on/#disqus_thread"
                  author="Peter Kirn, CDM"
                >
                  "ID700 is beautiful, focused, and musical."
                </Review>
                <Review
                  href="https://tapeop.com/reviews/gear/146/id700-vintage-digital-synthesizer-ios-app/?fbclid=IwAR2EdlYcS_a14BZKxUzgcB9Yka7QHz0QkN1ZskRdbiGeBi5gCXpwcZbr7q4"
                  author="John Baccigaluppi, Tape Op Magazine"
                >
                  "The sound of this instrument is gorgeous! The ID700 has deep,
                  evolving tones that take full advantage of complex envelopes
                  that can last for up to 128 seconds!"
                </Review>
                <Review
                  author="Jörg456, App Store review"
                  href="https://apps.apple.com/us/app/id700/id1482698043"
                >
                  This emulation of a vintage Buchla digital synth is just
                  fantastic and so far the best sounding synth app I have found
                  for an iPad.
                </Review>
              </div>
              <hr />
              <h3 id="downloads">Downloads</h3>
              <p>
                <DropboxLink href="https://www.dropbox.com/scl/fi/37icbruk431o54topy2lk/ID700-1.29-Manual.pdf?rlkey=iaoflsb6dzh4a2ub2no08396x&dl=1">
                  ID700 v1.29 Manual (2024-03-24)
                </DropboxLink>
              </p>
              <p>
                <DropboxLink href="https://www.dropbox.com/scl/fi/8b0kurazb9cskbllih4k3/ID700-VST3-1.8.0.zip?rlkey=4j7sdwp94h2jjm59vfnzugkg6&dl=0">
                  ID700 VST3 Wrapper v1.8.0 (2023-09-23)
                </DropboxLink>
              </p>
              <p>
                <DropboxLink href=" https://www.dropbox.com/s/t6pwuv034nvpbcq/Buchla%20700%20Manual.pdf?dl=0">
                  Buchla 700 Manual
                </DropboxLink>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
